<template>
  <div style="display: none">
    <!-- <pageTop></pageTop> -->
  </div>
  <iframe
    v-if="userInfoReadyTag"
    :src="iframeSrc"
    frameborder="0"
    id="gameframe"
    name="gameframe"
    style="width: 100%; height: 100%"
  ></iframe>
  <loading v-else></loading>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
// import pageTop from "../components/head/pageTop.vue";
import loading from "@/components/loading/loading.vue";

export default defineComponent({
  name: "Game",
  components: {
    // pageTop,
    loading,
  },
  setup() {
    let store = useStore();
    store.commit("set_showPageTop", false);
    let userInfoReadyTag = computed(() => {
      return store.state.userInfoReadyTag;
    });
    let iframeSrc = ref("");
    onMounted(() => {
      iframeSrc.value = "./game_race/index.html" + location.search;
      console.log("--lb", "thie is game_race!");
      store.commit("set_showPageTop", false);
    });
    return { userInfoReadyTag, iframeSrc };
  },
});
</script>
<style scoped>
iframe {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
